.PhoneInput {
  @apply rounded border border-ch-blue-alt-300 appearance-none
}

.PhoneInputCountry {
  @apply px-3 bg-ch-gray-200
}

.PhoneInputInput {
  @apply py-2 px-3
}

.PhoneInputInput:focus {
  @apply outline-none ring-2 ring-ch-blue-alt-300
}
