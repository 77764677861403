.react-pdf__Document {
  @apply flex flex-col max-w-full m-3
}

.react-pdf__Page__canvas {
  @apply h-auto w-auto
}

.react-pdf__Page {
  @apply shadow-md bg-white max-w-full mb-5
}
