/* 
  ReactTooltip padding option not working
  https://github.com/wwayne/react-tooltip/issues/752
*/
.__react_component_tooltip.popup-menu {
  padding: 0
}

.__react_component_tooltip.show.popup-menu {
  opacity: 1
}
