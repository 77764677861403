.mentions__suggestions__list {
  @apply bg-white rounded border border-ch-blue-alt-300 max-h-64 overflow-y-scroll
}

.mentions__control {
  @apply bg-white rounded
}

.mentions__input {
  @apply py-2 px-3 rounded border border-ch-blue-alt-300 appearance-none
}

.mentions-read-only__input,
.mentions__input {
  @apply text-opacity-100 opacity-100
}

.mentions__input:focus {
  @apply outline-none ring-2 ring-ch-blue-alt-300
}

.mentions__highlighter {
  @apply py-2 px-3
}

.mentions-read-only--singleLine .mentions-read-only__input {
  @apply truncate
}

.mentions-read-only__input:focus {
  @apply outline-none;
  caret-color: transparent;
}
