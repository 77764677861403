/* https://fullcalendar.io/docs/css-customization */
.fc-button:focus {
  outline: none !important;
  box-shadow: none !important;
} 

.fc-button-primary {
  background-color: #CECECE !important;
  color: white !important;
  border: none !important;
}

.fc-button-primary:focus {
  box-shadow: none !important;
}

.fc-prev-button, .fc-next-button {
  background-color: #F0F0F0 !important;
  border: 1px solid #E0E0E0 !important;
  padding: 0.25rem !important;
}

.fc-prev-button {
  border-radius: 9999px 0 0 9999px !important;

}

.fc-next-button {
  border-radius: 0 9999px 9999px 0 !important;
}

.fc-prev-button:hover, .fc-next-button:hover {
  background-color: #E0E0E0 !important;
  border: 1px solid #CECECE !important;
}

.fc-icon-chevron-left, .fc-icon-chevron-right {
  color: #13B7BF !important;
}

.fc-dayGridMonth-button {
  border-radius: 9999px 0 0 9999px !important;
  border-right: 1px solid white !important;
}

.fc-timeGridWeek-button {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
}

.fc-timeGridDay-button {
  border-radius: 0 9999px 9999px 0 !important;
  border-left: 1px solid white !important;
}

.fc-timeGridDay-button:hover,
.fc-timeGridWeek-button:hover,
.fc-dayGridMonth-button:hover {
  background-color: #13B7BF !important;
  border: 1px solid #0B9298 !important;
}

.fc-button-active {
  background-color: #13B7BF !important;
}

.fc-button-active:hover, .fc-button-active:focus {
  background-color: #13B7BF !important;
}

.fc-today-button {
  border-radius: 9999px !important;
}

.fc-today-button:not(:disabled):hover {
  background-color: #ADADAD !important;
}

.fc-today-button:disabled:hover {
  cursor: default;
}

.fc-day-today {
  background-color: #FFF5EC !important;
  background-clip: padding-box  ;
}

.fc-day-today .fc-daygrid-day-top {
  color: #13B7BF !important;
  font-weight: bold !important;
}

.fc-col-header-cell.fc-day-today {
  color: #13B7BF !important;
  font-weight: bold !important;
}

@media only screen and (max-width: 800px){
  .fc-today-button {
    display: none !important;
  }

  .fc-toolbar-title {
    font-size: 1em !important;
    text-align: center !important;
  }

  .fc-timeGridWeek-view .fc-col-header-cell {
    font-size: 0.8em !important;
  }
}

@media only screen and (max-width: 500px){
  .fc-timeGridWeek-view .fc-col-header-cell {
    font-size: 0.6em !important;
  }
}

